import React from "react"
import { Link } from "gatsby"

const Logo = ({ title }) => (
  <span className="logo-container">
    <Link className="logo" to="/">
      {title}
    </Link>
  </span>
)

export default Logo
