import React from "react"

import NavBar from "./navbar"
import Logo from "./logo"

const Header = ({ title }) => (
  <header className="primary-header">
    <div className="nav-container">
      <Logo title={title} />
      <NavBar />
    </div>
  </header>
)

export default Header
