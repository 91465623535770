import React from "react"

const Footer = ({ title }) => (
  <footer className="primary-footer">
    <div className="container">test</div>
    <div className="copyright">
      <span>
        copyright {new Date().getFullYear()}, {title}
      </span>
    </div>
  </footer>
)

export default Footer
