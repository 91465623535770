import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Seo from "./seo"
import Header from "./header"
import Footer from "./footer"

/* eslint-disable-next-line */
import typography from "../utils/typography"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        wpgraphql {
          allSettings {
            generalSettingsTitle
          }
        }
      }
    `}
    render={data => {
      const title = data.wpgraphql.allSettings.generalSettingsTitle

      return (
        <div className="page-container">
          <Seo title={title} />
          <Header title={title} />
          <div>
            <main>{children}</main>
          </div>
          <Footer title={title} />
        </div>
      )
    }}
  />
)

export default Layout
