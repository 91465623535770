import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const Navbar = () => (
  <StaticQuery
    query={graphql`
      query {
        wpgraphql {
          generalSettings {
            url
          }

          # Get the header menu (need to find a less explicit/fragile way)
          menu(id: "TWVudTo1") {
            menuItems {
              edges {
                node {
                  cssClasses
                  url
                  label
                  id
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const wpUrl = data.wpgraphql.generalSettings.url
      return (
        <nav className="primary-nav">
          <ul className="primary-nav-items nav-items">
            {data.wpgraphql.menu.menuItems.edges.map(({ node }) => (
              <li key={node.id}>
                <Link
                  to={node.url.replace(wpUrl, "")}
                  className={`${
                    node.cssClasses ? node.cssClasses + " " : ""
                  }primary-nav-link nav-link`}
                >
                  {node.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )
    }}
  />
)

export default Navbar
