import Typography from "typography"
import sutroTheme from "typography-theme-sutro"

sutroTheme.bodyFontFamily = ["Open Sans", "sans-serif"]
sutroTheme.bodyFontFamily = ["Open Sans", "sans-serif"]
sutroTheme.bodyColor = "var(--darkGray)"
sutroTheme.bodyWeight = "300"

sutroTheme.overrideStyles = ({ rhythm }, options) => ({
  "h1,h2,h3,h4,h5,h6": {
    textTransform: "uppercase",
  },
})

const typography = new Typography(sutroTheme)

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
